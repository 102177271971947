@tailwind base;
@tailwind components;
@tailwind utilities;

/*
NOTE: Tailwind require colors without color space function (rgb, hsl...) or opacity modifiers won’t work.
In order to preview colors we then must add a comment with hsl format for every color
Color preview extension: Color Highlight
*/
@layer base {
  :root {
    --background: 0 0% 100%; /* hsl(0, 0%, 100%) */
    --foreground: 219 92% 10%; /* hsl(219, 92%, 10%) */
    --foreground-light: 219 86% 20%; /* hsl(219, 86%, 20%) */

    --card: 0 0% 100%; /* hsl(0 0% 100%) */
    --card-foreground: 222.2 84% 4.9%; /* hsl(222.2, 84%, 4.9%) */

    --popover: 0 0% 100%; /* hsl(0, 0%, 100%) */
    --popover-foreground: 222.2 84% 4.9%; /* hsl(222.2, 84%, 4.9%) */

    --primary: 212 100% 50%; /* hsl(212, 100%, 50%) */
    --primary-foreground: 210 40% 98%; /* hsl(210, 40%, 98%) */
    --primary-50: 217.14 100% 95.88%; /*  hsl(217.14, 100%, 95.88%) */
    --muted-primary: 196, 41%, 93%; /* hsl(196, 41%, 93%) */
    --primary-100: 216.32 100% 92.55%; /* hsl(216.32, 100%, 92.55%) */
    --primary-400: 222 100% 71%; /*       hsl(222, 100%, 71%) */
    --primary-500: 212 100% 50%; /*       hsl(212, 100%, 50%) */

    --secondary: 178 100% 32%; /* hsl(178, 100%, 32%) */
    --secondary-foreground: 222.2 47.4% 11.2%; /* hsl(222.2, 47.4%, 11.2%) */

    --muted: 210 40% 96.1%; /* hsl(210, 40%, 96.1%) */
    --muted-foreground: 215.4 16.3% 46.9%; /* hsl(215.4, 16.3%, 46.9%) */

    --accent: 210 40% 96.1%; /* hsl(210, 40%, 96.1%) */
    --accent-foreground: 222.2 47.4% 11.2%; /* hsl(222.2, 47.4%, 11.2%) */

    --success: 120 100% 38%; /* hsl(120, 100%, 38%) */
    --muted-success: 124, 52%, 94%; /* hsl(124, 52%, 94%) */
    --success-foreground: 120 40% 98%; /* hsl(120, 40%, 98%) */
    --olive: 70 100% 34%; /* hsl(70, 100%, 34%) */

    --destructive: 0 84.2% 60.2%; /* hsl(0, 84.2%, 60.2%) */
    --destructive-foreground: 210 40% 98%; /* hsl(210, 40%, 98%) */

    --lin: 38 57% 92%; /* hsl(38, 57%, 92%) */
    --alt-background: 0 0% 85%; /* hsl(0, 0%, 85%) */
    --complementary: 9.03 93% 60.78%; /* hsl(9.03, 93%, 60.78%) */
    --muted-complementary: 23.03 98% 70.78%; /* hsl(23.03, 98%, 70.78%) */
    --alt-muted-complementary: 36, 93%, 89%; /* hsl(36, 93%, 89%) */

    --golden: 55, 92%, 58%; /* hsl(55, 92%, 58%) */
    --alt-golden: 55, 64%, 54%; /* hsl(55, 64%, 54%) */
    --muted-golden: 57, 90%, 96%; /* hsl(57, 90%, 96%) */

    --purple: 266 100% 75%; /* hsl(266, 100%, 75%) */

    --border: 214.3 31.8% 91.4%; /* hsl(214.3, 31.8%, 91.4%) */
    --input: 214.3 31.8% 91.4%; /* hsl(214.3, 31.8%, 91.4%) */
    --ring: 222.2 84% 4.9%; /* hsl(222.2, 84%, 4.9%) */

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }

  /* Locks the scroll of the body if the attrbute `page-locked` is found in an element in the DOM */
  body:has([page-locked]) {
    @apply overflow-hidden h-screen;
  }
}

/* https://stackoverflow.com/a/44543771 */
.grecaptcha-badge {
  visibility: hidden !important;
}

/* https://stackoverflow.com/questions/75371915/recharts-piechart-suppress-pie-selection-border */
.recharts-wrapper * {
  outline: none !important;
}

/* In order to handle a smooth anchor behavior we need to add this property to the html https://stackoverflow.com/questions/71952611/smooth-scrolling-when-clicking-an-anchor-link-on-react-next-js */
html {
  scroll-behavior: smooth;
}
